import { EventEmitter } from 'events';
import { Client, ActivationState } from '@stomp/stompjs';
import uuidv1 from 'uuid/v1';


const MAX_RECONNECT_ATTEMPTS = 5;

class Broker extends EventEmitter {
  constructor(url, kauth) {
    super();
    this.kauth = kauth;
    this.session = uuidv1();
    this.connectionRetries = 0;

    this.client = new Client({
      brokerURL: url,
      reconnectDelay: 200
    });
    this.client.beforeConnect = this.beforeConnect.bind(this);
    this.client.onConnect = this.onConnect.bind(this);
    this.client.onDisconnect = this.onDisconnect.bind(this);
    this.client.onWebSocketError = this.onError.bind(this);
    this.client.onStompError = this.onError.bind(this);
  }

  get active() {
    return this.client.state === ActivationState.ACTIVE;
  }

  async connect() {
    if (this.active) return;
    if (this.kauth) {
      const token = await this.kauth.getValidToken();
      this.client.connectHeaders = { login: '#token', passcode: token };
    }
    this.client.activate();
  }

  async disconnect() {
    this.session = null;
    this.connectionRetries = 0;
    return this.client.deactivate();
  }

  async beforeConnect() {
    const token = await this.kauth.getValidToken();
    this.client.connectHeaders = { login: '#token', passcode: token };
  }

  onConnect() {
    this.connectionRetries = 0;
    this.emit('connected');
  }

  onDisconnect() {
    this.emit('disconnected');
    if (this.active) {
      this.connectionRetries++;
      if (this.connectionRetries > MAX_RECONNECT_ATTEMPTS) {
        this.client.deactivate();
        this.emit('error', new Error(`Connection failed after ${this.connectionRetries} retries`));
      }
    }
  }

  onError(err) {
    this.emit('error', err);
  }

  onMessage(msg) {
    msg.ack();
    this.emit('message', msg);
  }

  subscribe(...args) {
    return this.client.subscribe(...args);
  }

  unsubscribe(...arg) {
    this.client.unsubscribe(...arg);
  }

  publish(pubObj) {
    this.client.publish(pubObj);
  }
}

export default Broker;
