<template>
  <div class="wrapper">
    <b-row>
      <b-col>
        <b-card bg-variant="light" header="STOMP Broker">
          <b-form-group>
            <b-input-group>
              <b-form-input placeholder="URL" v-model="url"></b-form-input>
              <b-input-group-append v-if="!active">
                <b-button variant="primary" @click="connect">Connect</b-button>
              </b-input-group-append>
              <b-input-group-append v-else>
                <b-button variant="danger" @click="disconnect">Disconnect</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <b-row>
            <b-col md="6">
              <b-card header="Send Message">
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-form-select
                        v-model="destinationType"
                        :plain="true"
                        :options="['Queue', 'Topic']"
                        class="method"
                      ></b-form-select>
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Destination"
                      v-model="destination"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary" @click="sendMessage"
                        >Send</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <h5>Headers</h5>
                <key-value-editor v-model="headers"></key-value-editor>
                <h5>Body</h5>
                <prism-editor
                  language="js"
                  class="editor"
                  v-model="body"
                ></prism-editor>
              </b-card>
            </b-col>

            <b-col md="6">
              <b-card header="Subscribe Destination">
                <b-form-group>
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-form-select
                        v-model="subDestinationType"
                        :plain="true"
                        :options="['Queue', 'Topic']"
                        class="method"
                      ></b-form-select>
                    </b-input-group-prepend>
                    <b-form-input
                      placeholder="Destination"
                      v-model="subDestination"
                    ></b-form-input>
                    <b-input-group-append v-if="subId">
                      <b-button variant="danger" @click="unsubscribe"
                        >Unsubscribe</b-button
                      >
                    </b-input-group-append>
                    <b-input-group-append v-else>
                      <b-button variant="primary" @click="subscribe"
                        >Subscribe</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
                <h5>Selector</h5>
                <b-form-input
                  size="sm"
                  class="mb-4"
                  type="text"
                  v-model="subSelector"
                  :disabled="!!subId"
                ></b-form-input>
                <h5>Messages</h5>
                <b-list-group v-if="messages.length > 0" class="messages">
                  <template v-for="message in messages">
                    <b-list-group-item :key="message.headers['message-id']">
                      <vue-json-pretty
                        :deep="1"
                        :data="message"
                      ></vue-json-pretty>
                    </b-list-group-item>
                  </template>
                </b-list-group>
              </b-card>
              <b-card v-if="brokerAdmin" header="Tools">
                <b-button variant="primary" @click="reloadKeycloakResources"
                  >Reload Keycloak Resources</b-button
                >
              </b-card>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import 'prismjs';
import 'prismjs/themes/prism.css';
import VueJsonPretty from 'vue-json-pretty';
import PrismEditor from 'vue-prism-editor';
import 'vue-prism-editor/dist/VuePrismEditor.css';
import miniToastr from 'mini-toastr';
import uuidv1 from 'uuid/v1';
import KeyValueEditor from '../components/KeyValueEditor.vue';
import Broker from '../shared/broker';

miniToastr.init();

export default {
  name: 'brokertester',
  components: {
    VueJsonPretty,
    PrismEditor,
    KeyValueEditor
  },
  data() {
    const sessionId = uuidv1();
    return {
      active: false,
      url: 'wss://broker.morgansolar.xyz/stomp',
      destination: 'msi.meerkat.Device.TOO.echo',
      destinationType: 'Queue',
      body: JSON.stringify({
        code: '01',
        ver: '1.0'
      }, null, 2),
      headers: {
        'reply-to': '',
        session: sessionId,
        'correlation-id': 0
      },
      subDestinationType: 'Queue',
      subDestination: '',
      subSelector: `session = '${sessionId}'`,
      subId: '',
      messages: []
    };
  },
  created() {
    const username = this.$kauth.tokenParsed.preferred_username;
    this.subDestination = `msi.meerkat.User.${username}`;
    this.headers['reply-to'] = `msi.meerkat.User.${username}`;
  },
  destroyed() {
    this.unsubscribe();
    this.broker.disconnect();
    this.broker.removeAllListeners('error');
  },
  watch: {
    url: {
      handler(val) {
        if (this.broker && this.broker.url === val) return;
        if (this.broker) this.broker.disconnect();
        this.broker = new Broker(val, this.$kauth);
        this.broker.on('error', err => miniToastr.error(err.message));
        this.active = false;
      },
      immediate: true
    }
  },
  computed: {
    brokerAdmin() {
      const { activemq } = this.$kauth.tokenParsed.resource_access;
      return activemq && activemq.roles.includes('admin');
    },
  },
  methods: {
    connect() {
      this.broker.connect();
      this.active = true;
    },
    disconnect() {
      this.broker.disconnect();
      this.active = false;
    },
    sendMessage() {
      const headers = Object.assign({
        timestamp: Date.now(),
        expires: Date.now() + 600000
      }, this.headers);

      this.broker.publish({
        skipContentLengthHeader: true,
        destination: `/${this.destinationType.toLowerCase()}/${this.destination}`,
        headers,
        body: this.body
      });

      if (!Number.isNaN(this.headers['correlation-id'])) {
        this.headers['correlation-id']++;
      }
    },
    subscribe() {
      this.unsubscribe();
      const subOpt = { ack: 'client-individual' };
      if (this.subSelector) subOpt.selector = this.subSelector;
      const { id } = this.broker.subscribe(this.subDestination, msgData => this.onMessage(msgData), subOpt);
      this.subId = id;
    },
    unsubscribe() {
      if (this.subId) {
        this.broker.unsubscribe(this.subId);
        this.subId = null;
      }
    },
    onMessage(msg) {
      const { body, headers } = msg;
      this.messages.unshift({ body, headers });
      msg.ack();
    },
    reloadKeycloakResources() {
      const headers = {
        expires: Date.now() + 600000,
        'content-length': false
      };

      this.broker.publish({ destination: 'msi.meerkat.Broker.ReloadResources', headers, body: 'reload' });
    }
  }
};
</script>

<style scoped>
</style>
